@charset "utf-8";

@import "bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/variables";
@import "yeti/variables";
@import "bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap";
@import "yeti/bootswatch";
@import "syntax-highlighting";
@import "bower_components/font-awesome/scss/variables";
@import "bower_components/font-awesome/scss/font-awesome";

.col-centered {
    float: none;
    margin: 0 auto;
}

#toTop{
//  opacity: 0.6;
  position: fixed;
  bottom: 45px;
  right: 40px;
  cursor: pointer;
  display: none;
}

#toTop .fa {
  font-size: 24px;
}

.bs-docs-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 70px;
  color: #767676;
  text-align: center;
  border-top: 1px solid #e5e5e5;
}

.bs-docs-footer-links {
  padding-left: 0;
  margin-top: 10px
}

.bs-docs-footer-links li {
  display: inline;
  padding: 0 2px
}

.bs-docs-footer-links li:first-child {
  padding-left: 0
}